import React from "react";
import "./about.css";
import ME from "../../assets/me2.jpg";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscWorkspaceTrusted ,VscBug} from "react-icons/vsc";
function About() {
  return (
    <section id="about">
      <h5>Get To know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="/" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
          <article className="about__card">
              <FaAward className="about__icon" />
              <h6>6+ years of Experience</h6>
            </article>
          <article className="about__card">
              <VscWorkspaceTrusted className="about__icon" />
              <h6>Masters Degree In CS</h6>
            </article>
            <article className="about__card">
          <FiUsers className="about__icon" />
          <h6>Strong Communication Skills</h6>
            </article>
            <article className="about__card">
              <VscBug className="about__icon" />
              <h6>Agile and Adaptable</h6>
          </article>
          </div>

          <p style={{textAlign:"justify", color:"white"}}>
          Results-driven full-stack engineer with over 6 years of comprehensive experience in JavaScript, TypeScript, React, Next.js, Python, and Node.js. Adept at crafting high-performance applications with a demonstrated track record of excellence. Proven ability in end-to-end development, showcasing expertise across diverse technologies and frameworks. Recognized for consistently delivering impactful solutions that enhance overall project success. 
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
}

export default About;
