import React from "react";
import { BsPatchCheckFill } from "react-icons/bs";
import "./experience.css";
const Experience = () => {
  return (
    <section id="experience">
      <h5>Skills I Have</h5>
      <h2>My Experience</h2>
      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Frontend Developement</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>HTML</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>CSS/SCSS</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>SASS</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>React JS</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>Next JS</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>Redux</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>Typscript</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>{" "}
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>TS/ES6</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>Material UI</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>Bootstrap</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>

          </div>
        </div>
        <div className="experience__backend">
          <h3>Backend Developement</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>Python</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>Fast API</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>Node JS</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>Swagger</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>REST API</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>Java</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>C/C++</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>Graph QL</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>{" "}
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>Traefik</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>Locust</h4>
                <small className="text-ligh">Experienced</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
