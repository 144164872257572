import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { IoLogoTwitter } from "react-icons/io";
import './footer.css'
function footer() {
  return (
    <footer>
      <ul className="permalinks">
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#experience">Experience</a>
        </li>
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
      </ul>
      <div className="footer_socials">
        <a href="https://www.facebook.com/moe.msh.3?mibextid=LQQJ4d" target="_black">
          <FaFacebookF />
        </a>
        <a href="https://www.instagram.com/mo_mchawrab?igshid=ODA1NTc5OTg5Nw%3D%3D&utm_source=qr" target="_black">
          <FiInstagram />
        </a>
        <a href="http">
          <IoLogoTwitter />
        </a>
      </div>
    </footer>
  );
}

export default footer;
