import React from "react";
import "./header.css";
import CTA from "./CTA";
import ME from "../../assets/me1.png";
import HeaderSocials from "./headerSocials";
const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Mo Mchawrab</h1>
        <h5 className="text-light">Full Stack Engineer</h5>
        <CTA />
        <HeaderSocials />
        <div style={{ marginTop: "100px" }}>
          <img width={"100%"} height={"100%"} src={ME} alt="me" />
        </div>
        <a href="contact" className="scroll__down">
          Scroll Down
        </a>
      </div>
    </header>
  );
};

export default Header;
