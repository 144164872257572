import React from "react";

import IMG1 from "../../assets/portfolio1.jpg";
import IMG2 from "../../assets/drimblemedia_cover.jpeg";
import IMG3 from "../../assets/claimes.png";

import "./portfolio.css";

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio_container">
        <article className="portfolio_item">
          <div className="portfolio_item-image">
            <img src={IMG1} alt="Portfolio Image" />
          </div>
          <h3>Trading Platform</h3>
          <div className="portfolio_item-cta">
            <a href="https://github.com" className="btn" target="_blank">
              Github
            </a>
            <a
              href="https://www.lereum.com"
              className="btn btn-primary"
              target="www.lereum.com"
            >
              Live Demo
            </a>
          </div>
        </article>
        <article className="portfolio_item">
          <div className="portfolio_item-image">
            <img src={IMG2} alt="Portfolio Image" />
          </div>
          <h3>Drimble Media</h3>
          <div className="portfolio_item-cta">
            <a href="https://github.com" className="btn" target="_blank">
              Github
            </a>
            <a
              href="https://www.drimble.nl/bedrijf"
              className="btn btn-primary"
              target="www.drimble.nl/bedrijf"
            >
              Live Demo
            </a>
          </div>
        </article>
        <article className="portfolio_item">
          <div className="portfolio_item-image">
            <br/>
            <img src={IMG3} alt="Portfolio Image" />
            <br/>
            <br/>
          </div>
          <h3>Claims management system</h3>
          <div className="portfolio_item-cta">
            <a href="https://github.com" className="btn" target="_blank">
              Github
            </a>
            <a
              href="https://arcinarisk.com"
              className="btn btn-primary"
              target="_blank"
            >
              Live Demo
            </a>
          </div>
        </article>
      </div>
    </section>
  );
};

export default Portfolio;
